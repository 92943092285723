






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Stores Management",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Name",
              sortable: true,
            },
            description: {
              text: "Description",
              sortable: true,
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            lastSyncTime: {
              text: "Last Sync Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            lastSyncStatus: {
              text: "Last Sync Status",
              sortable: true,
              options: {
                preTransform(_, item) {
                  return item.lastSyncSuccessTime && !item.lastSyncError;
                },
                boolean: true,
              },
            },
            lastSyncSuccessTime: {
              text: "Last Sync Success Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            orderDeskUrl: {
              text: "Order Desk URL",
              sortable: true,
            },
            orderDeskStoreId: {
              text: "Order Desk Store ID",
              sortable: true,
            },
            orderDeskFolderNames: {
              text: "Order Desk Folder Names",
              sortable: true,
              options: {
                labels: true,
              },
            },
            action: {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              syncOrders: {
                attrs: {},
                content: {
                  icon: "mdi-sync",
                },
                on: {
                  async xClick({ self }) {
                    const { item, dataTable } = self.context();
                    self.loading.value = true;
                    await coreApiClient.call("stores", "syncOrdersForItem", {
                      id: item._id,
                    });
                    self.loading.value = false;
                    dataTable.refresh();
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Sync Orders",
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("stores", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("stores", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "stores",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("stores", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Name",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
              lastSyncSuccessTime: {
                type: "XTimePicker",
                attrs: {
                  label: "Last Sync Success Time",
                },
              },
              orderDeskUrl: {
                attrs: {
                  label: "Order Desk URL",
                },
              },
              orderDeskStoreId: {
                attrs: {
                  label: "Order Desk Store ID",
                },
              },
              orderDeskApiKey: {
                attrs: {
                  label: "Order Desk API Key",
                },
              },
              orderDeskFolderNames: {
                type: "textMultiple",
                attrs: {
                  label: "Order Desk Folder Name",
                },
              },
            },
            insertForm: userManager.checkRole(["admin"]) && {
              content: {
                fieldNames: [
                  "name",
                  "description",
                  "orderDeskUrl",
                  "orderDeskStoreId",
                  "orderDeskApiKey",
                  "orderDeskFolderNames",
                ],
              },
            },
            editForm: userManager.checkRole(["admin"]) && {
              content: {
                fieldNames: [
                  "name",
                  "description",
                  "lastSyncSuccessTime",
                  "orderDeskUrl",
                  "orderDeskStoreId",
                  "orderDeskApiKey",
                  "orderDeskFolderNames",
                ],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
